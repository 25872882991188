import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'

import SEO from '../components/seo'
import RenderBlock from '../utils/render-block'

class ProjectTemplate extends Component {

  render() {
    let page = this.props.data.wordpressWpProject
    let metaDescription = page.yoast_meta.find(x => x.name === 'description') ? page.yoast_meta.find(x => x.name === 'description').content : '';
    let image = page.acf.feature_image.localFile.childImageSharp.original

    return (
      <>
        <SEO title={he.decode(page.yoast_title)} bodyClass={'project'} description={metaDescription} />
        <section className='project__title'>
          <Fade bottom distance='40px'>
            <div className='project__title__inner'>
              <h1>{ page.title }</h1>
              <picture>
                <img src={image.src} alt={page.acf.feature_image.alt_text} width={image.width} height={image.height} />
              </picture>
            </div>
          </Fade>
        </section>
        { page.acf.content_blocks_project && page.acf.content_blocks_project.map((el, i) => RenderBlock(el.__typename, el, i)) }
      </>
    )
  }
}

export default ProjectTemplate

export const query = graphql`
  query ($id: String!) {
    wordpressWpProject(id: {eq: $id}) {
      yoast_title
      yoast_meta {  
       name
       content
       property
      }
      title
      acf {
        feature_image {
          ...original
        }
        content_blocks_project {
          __typename
          ... on WordPressAcf_project_description_text_module {
            primary_text
            secondary_text
          }
          ... on WordPressAcf_image_text_module {
            content
            side
            image {
              ...original
            }
          }
          ... on WordPressAcf_module_image_columns {
            images {
              image {
                ...original
              }
              size
            }
          }
          ... on WordPressAcf_slideshow_module {
            images {
              ...original
            }
          }
          ... on WordPressAcf_flexible_footer_module {
            lists {
              title
              items {
                text
                url
              }
            }
          }
          ... on WordPressAcf_pullout_text_module {
            first_column
            second_column
          }
          ... on WordPressAcf_related_posts {
            posts {
              post {
                type:post_type
                title:post_title
                slug:post_name
              }
              description
            }
          }
        }
      }
    }
  }
`
